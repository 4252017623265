import { createApp } from 'vue';
import './assets/styles.css';

// Determine which component to load based on the environment
//const isDevelopment = process.env.NODE_ENV === 'production';
//const Component = isDevelopment ? require('./DevComponent.vue').default : require('./App.vue').default;

//const app = createApp(Component);
import App from './App.vue';
import lenis from './tools/lenis.js';

const app = createApp(App);
app.config.globalProperties.$lenis = lenis;

app.mount('#app');