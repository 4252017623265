<template>
  <header>
    <div id="logo">
      <h1>Art&#239;sNov&#233;l</h1>
    </div>
    <nav :class="{ open: menuOpen }">
      <a href="#about">About</a>
      <a href="#contact">Contact</a>
    </nav>
    <button @click="toggleMenu" class="menu-button">☰</button>
  </header>
</template>

<script setup>

import { ref, onMounted, nextTick } from 'vue';
import { gsap } from 'gsap';

const menuOpen = ref(false);

const toggleMenu = () => {
  menuOpen.value = !menuOpen.value;
};

onMounted(async () => {
  await nextTick();
  gsap.from('#logo', {
    opacity: 0,
    rotateX: 90,
    y: 50,
    duration: 2,
    delay: 10
  });
});
</script>

<style>
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #111;
  color: white;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 60px;

}

#logo h1 {
  margin: 0;
  font-size: 1.5rem;
}

nav {
  display: flex;
  margin: 20px 0px;
}

nav a {
  color: white;
  font-size: 1rem;
  text-decoration: none;
  padding: 10px 15px;
  transition: background-color 0.3s ease-in-out;
  border-radius: 6px;
}

nav a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.menu-button {
  display: none;
  background: none;
  color: white;
  font-size: 2rem;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

@media (max-width: 768px) {
  nav {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #111;
    flex-direction: column;
    align-items: center;
    display: none;
  }

  nav.open {
    display: flex;
  }

  nav a {
    margin: 10px 0;
  }

  .menu-button {
    display: block;
  }
}
</style>