<template>
  <section class="hero" id="loading" @click="changeTrailColor">
    <div class="loading-content">
      <h1 ref="loadingText" id="subtitle"><span class="dynamic-word"></span></h1>
    </div>
    <div id="wordTrail" ref="wordTrail"></div>
    <div v-if="showColorSuggestion" class="color-suggestion">
      Click to change trail color!
    </div>
    <div v-if="showScrollMessage" class="scroll-message">
      Scroll down to explore
      <div class="arrow">⌵</div>
    </div>
  </section>
</template>

<script>
import { onMounted, ref, nextTick } from 'vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'HeroComponent',
  setup() {
    const loadingText = ref(null);
    const wordTrail = ref(null);
    const cursor = ref(null);
    const showScrollMessage = ref(false);
    const showColorSuggestion = ref(false);
    const dynamicWords = ['Design', 'Creativity', 'Innovation', 'Inspiration', 'Fun'];
    const colors = ['#ffffff', '#ff0000', '#00ff00', '#00bfff', '#ffff00'];
    let colorIndex = 0;
    const addedLength = 85;
    const cursorEnabled = ref(false);
    const allowScroll = ref(false);

    let prevX = 0;
    let prevY = 0;
    let trailCounter = 0;
    let firstClick = false;

    const changeTrailColor = () => {
      colorIndex = (colorIndex + 1) % 6;
      firstClick = true;
      showColorSuggestion.value = false;
      if (cursor.value) {
        cursor.value.style.display = 'none';
      }
    };

    const getTrailColor = () => {
      if (colorIndex === 5) {
        const color = colors[trailCounter % colors.length];
        trailCounter++;
        return color;
      } else {
        return colors[colorIndex];
      }
    };

    const createTrailWord = (x, y) => {
      const deltaX = x - prevX;
      const deltaY = y - prevY;
      prevX = x;
      prevY = y;

      const angle = Math.atan2(deltaY, deltaX);
      let offsetX = Math.cos(angle) * 20;
      let offsetY = Math.sin(angle) * 10;

      if (deltaX >= 0) {
        offsetX += addedLength * Math.cos(angle);
      }

      if (deltaY > 0) {
        offsetY += 20;
      }

      const word = dynamicWords[Math.floor(Math.random() * dynamicWords.length)];
      const span = document.createElement('span');
      span.textContent = word;
      span.className = 'trail-word';
      span.style.position = 'absolute';
      span.style.left = `${x - offsetX}px`;
      span.style.top = `${y - offsetY}px`;
      span.style.color = getTrailColor();
      wordTrail.value.appendChild(span);

      gsap.fromTo(span, {
        opacity: 1,
        scale: 1,
        x: 0,
        y: 0
      }, {
        opacity: 0,
        scale: 1.5,
        x: (Math.random() - 0.5) * 100,
        y: (Math.random() - 0.5) * 100,
        duration: 1,
        onComplete: () => {
          wordTrail.value.removeChild(span);
        }
      });
    };

    onMounted(() => {
      nextTick(() => {
        
        // GSAP timeline for word changes
        const tl = gsap.timeline({
          onComplete: () => {
            cursorEnabled.value = true;
            if (cursor.value) {
              cursor.value.classList.add('active');
            }
            colorIndex = 0;
          }
        });
        tl.from("#subtitle", {
          duration: 0.5,
          opacity: 0,
          ease: "power1.inOut"
        });
        dynamicWords.forEach((word) => {
          tl.to(loadingText.value.querySelector('.dynamic-word'), {
            opacity: 0,
            duration: 0.2,
            ease: "power1.inOut",
            onComplete: () => {
              loadingText.value.querySelector('.dynamic-word').textContent = word;
            }
          })
            .fromTo(loadingText.value.querySelector('.dynamic-word'), {
              scale: 5,
              opacity: 0,
            }, {
              scale: 1,
              opacity: 1,
              duration: 0.4,
              ease: "bounce.out",
              delay: 0.1
            })
            .to(loadingText.value.querySelector('.dynamic-word'), {
              duration: 0.2,
              ease: "none"
            });
        });

        // Custom animation for "ArtïsNovél"
        tl.to(loadingText.value.querySelector('.dynamic-word'), {
          opacity: 0,
          duration: 0.9,
          ease: "power1.inOut",
          onComplete: () => {
            loadingText.value.innerHTML = '<span class="letter">A</span><span class="letter">r</span><span class="letter">t</span><span class="letter">ï</span><span class="letter">s</span><span class="letter">N</span><span class="letter">o</span><span class="letter">v</span><span class="letter">é</span><span class="letter">l</span>';
          }
        })
          .add(() => {
            const letters = loadingText.value.querySelectorAll('.letter');
            letters.forEach((letter) => {
              gsap.fromTo(letter, {
                opacity: 0,
                scale: 10,
              }, {
                opacity: 1,
                scale: 5,
                duration: 3,
                ease: "power1.out",
                delay: (Math.random() * 0.8) + 1.1 // Random delay for each letter
              });
            });
          })
          .to(loadingText.value.querySelector('.dynamic-word'), {
            duration: 3,
            ease: "none"
          });

        tl.to("#subtitle", {
          duration: 2,
          scale: 200,
          opacity: 0,
          delay: .8,
          ease: "power1.inOut"
        });

        // Mousemove event to create word trail
        window.addEventListener('mousemove', (event) => {
          if (!cursorEnabled.value) return;
          createTrailWord(event.clientX, event.clientY);
        });

        // Touchstart and touchmove events to create word trail and prevent default scrolling
        window.addEventListener('touchstart', () => {
          if (!cursorEnabled.value) return;
          allowScroll.value = false;
        });

        window.addEventListener('touchmove', (event) => {
          if (!cursorEnabled.value || allowScroll.value) return;
          event.preventDefault();
          const touch = event.touches[0];
          createTrailWord(touch.clientX, touch.clientY);
        });

        window.addEventListener('touchend', () => {
          allowScroll.value = true;
        });

        // Show scroll message if user hasn't scrolled within 15 seconds
        setTimeout(() => {
          showScrollMessage.value = true;
        }, 32000);

        setTimeout(() => {
          showColorSuggestion.value = false;
        }, 42000);

        setTimeout(() => {
          if (!firstClick) {
            showColorSuggestion.value = true;
          }
        }, 22000);
      });
    });

    return {
      loadingText,
      wordTrail,
      cursor,
      showScrollMessage,
      showColorSuggestion,
      getTrailColor,
      changeTrailColor,
      createTrailWord
    };
  }
};
</script>

<style scoped>
.hero {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #111;
  color: white;
  text-align: center;
  cursor: none;
  overflow: hidden;
}

h1 {
  font-size: 3rem;
  margin: 0;
  white-space: nowrap;
}

.dynamic-word {
  display: inline-block;
  white-space: nowrap;
  opacity: 1;
  position: relative;
  left: 0;
}

.letter {
  display: inline-block;
}

#wordTrail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow-x: hidden;
}

.trail-word {
  font-size: 1rem;
  white-space: nowrap;
}

.custom-cursor {
  position: fixed;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
}

.custom-cursor.active {
  display: block;
}

.scroll-message {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 1rem;
  z-index: 1001;
  opacity: 0;
  animation: fadeIn 1s forwards;
  box-sizing: border-box;
}

.arrow {
  font-size: 2rem;
  margin-top: 10px;
  animation: bounce 2s infinite;
}

.color-suggestion {
  position: absolute;
  bottom: 110px;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 1rem;
  z-index: 1001;
  animation: fadeIn 2s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(-5px);
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  .scroll-message,
  .color-suggestion {
    font-size: 0.8rem;
  }

  .arrow {
    font-size: 1.5rem;
  }
}
</style>
