<template>
  <section class="side-scroll" id="side-scroll">
    <div class="side-scroll-container">
      <h1 id="scrollH1">Welcome to</h1>
    </div>
    <div class="an-title">
      <div class="glitch top">Art&#239;sNov&#233;l</div>
      <div class="glitch bottom">Art&#239;sNov&#233;l</div>
    </div>
    <div class="intro-container">
      <h1 class="intro" id="m1">M</h1>
      <h1 class="intro" id="y1">y&nbsp;</h1>
      <h1 class="intro" id="n2">n</h1>
      <h1 class="intro" id="a2">a</h1>
      <h1 class="intro" id="m2">m</h1>
      <h1 class="intro" id="e2">e&nbsp;</h1>
      <h1 class="intro" id="i3">i</h1>
      <h1 class="intro" id="s3">s&nbsp;</h1>
      <h1 class="intro" id="c4">C</h1>
      <h1 class="intro" id="h4">h</h1>
      <h1 class="intro" id="r4">r</h1>
      <h1 class="intro" id="i4">i</h1>
      <h1 class="intro" id="s4">s</h1>
      <h1 class="intro" id="period">.</h1>
    </div>
    <div class="follow-text">
      <div id="follow-1">I'm here</div>
      <div id="follow-2">to bring your</div>
      <div id="follow-3">web projects</div>
      <div id="follow-4">to life with...</div>
      <div id="replace-text-container">
      <span id="replace-text"></span></div>
    </div>
    <div class="close-out">
      <p id="note">This website is a work in progress.  Check back from time to time to see what I'm up to!</p>
      <p id="thanks">Thanks for stopping by!</p>
      <p id="email">info@workwisesolutions.net</p>
    </div>
  </section>


</template>

<script>
import { onMounted } from 'vue';
import { gsap, Power4, TextPlugin } from 'gsap/all';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(TextPlugin);

export default {
  name: 'SideScrollComponent',
  setup() {

    onMounted(() => {
      const tlp1 = gsap.timeline({
        scrollTrigger: {
          trigger: '.side-scroll',
          start: 'top top',
          end: '+=1300%',
          scrub: 3,
          pin: true,
          anticipatePin: 1,
        }
      });

      tlp1.to('#scrollH1', {
        x: "-111%",
        ease: "power1.inOut",
        duration: 2,
      }).fromTo('.an-title', {
        opacity: 0,
        scale: 0.2,
      }, {
        opacity: 1,
        scale: 1,
        ease: "power1.inOut",
        duration: 2
      }, "-=2")
        .to('.glitch', { duration: 0.2, scaleY: 1.1, ease: Power4.easeInOut })
        .to('.glitch', { duration: 0.1, skewX: 70, ease: Power4.easeInOut })
        .to('.glitch', { duration: 0.04, skewX: 0, ease: Power4.easeInOut })
        .to('.glitch', { duration: 0.04, opacity: 0 })
        .to('.glitch', { duration: 0.04, opacity: 1 })
        .to('.glitch', { duration: 0.04, x: -20 })
        .to('.glitch', { duration: 0.04, x: 0 })
        .add("split", 0)
        .to('#an-glitch', { duration: 0, scale: 1.1 }, 'split')
        .to('.bottom', { duration: 0.4, x: 7, ease: Power4.easeInOut }, 'split')
        .to('#an-glitch', { duration: 0, scale: 1 }, "+=0.02")
        .to('.glitch', { duration: 0.08, textShadow: '-3px 0 red' }, "+=0.09")
        .to('.glitch', { duration: 0.03, textShadow: '-3px 0 green' }, 'split')
        .to('.glitch', { duration: 0.03, textShadow: 'none' }, "+=0.01")
        .to('.top', { duration: 0.2, x: 0, ease: Power4.easeInOut })
        .to('.bottom', { duration: 0.2, x: 0, ease: Power4.easeInOut })
        .to('.glitch', { x: "110%", ease: Power4.easeInOut })
        /*.add(() => {
          introTl.play();
        });

      const introTl = gsap.timeline({ paused: true });
      introTl*/
      .from('.intro-container', { opacity: 0, ease: Power4.easeInOut }, "-=.5")
      .from('#y1', { x: "732%", y: "523%", rotate: 143, ease: Power4.easeInOut })
        .from('#i4', { x: "-1150%", y: "275%", rotate: 325, duration: 1, ease: Power4.easeInOut }, "-=.9")
        .from('#e2', { x: "-250%", y: "-350%", rotate: 225, duration: 1, ease: Power4.easeInOut }, "-=.9")
        .from('#a2', { x: "650%", y: "-478%", rotate: 186, duration: 1, ease: Power4.easeInOut }, "-=.9")
        .from('#s3', { x: "512%", y: "-150%", rotate: 297, duration: 1, ease: Power4.easeInOut }, "-=.9")
        .from('#h4', { x: "-1163%", y: "525%", rotate: 341, duration: 1, ease: Power4.easeInOut }, "-=.9")
        .from('#i3', { x: "-1550%", y: "-50%", rotate: 273, duration: 1, ease: Power4.easeInOut }, "-=.9")
        .from('#c4', { x: "-250%", y: "-250%", rotate: 122, duration: 1, ease: Power4.easeInOut }, "-=.9")
        .from('#n2', { x: "1250%", y: "450%", rotate: 629, duration: 1, ease: Power4.easeInOut }, "-=.9")
        .from('#m2', { x: "-250%", y: "250%", rotate: 308, duration: 1, ease: Power4.easeInOut }, "-=.9")
        .from('#m1', { x: "-250%", y: "-250%", rotate: 250, duration: 1, ease: Power4.easeInOut }, "-=.9")
        .from('#r4', { x: "320%", y: "-390%", rotate: 25, duration: 1, ease: Power4.easeInOut }, "-=.9")
        .from('#s4', { x: "-250%", y: "-250%", rotate: 399, duration: 1, ease: Power4.easeInOut }, "-=.9")
        .from('#period', { y: -100, delay: 1, opacity: 0, scale: 20, duration: .7, ease: Power4.easeInOut }, "-=.9")
        .to('.intro-container', { opacity: 0, ease: Power4.easeInOut, duration: 1 }, "+=.4")
        .from('.follow-text', { opacity: 0, ease: Power4.easeInOut, duration: 1 }, "-=.5")
        .from('#follow-1', { opacity: 0, scale: .3, ease: "power3.out", duration: .5 }, "-=.3")
        .from('#follow-2', { opacity: 0, scale: .3, ease: "power3.out", duration: .5 }, "-=.3")
        .from('#follow-3', { opacity: 0, scale: .3, ease: "power3.out", duration: .5 }, "-=.3")
        .from('#follow-4', { opacity: 0, scale: .3, ease: "power3.out", duration: .5 }, "-=.3")
        .to('#replace-text', {
        duration: 1,
        delay: .5,
        text: "creativity",
        color: "#FFD700",
        ease: "none",
      }).to('#replace-text', {
        duration: 1,
        delay: .5,
        text: "collaboration",
        color: "#7FFF00",
        ease: "none",
      }).to('#replace-text', {
        duration: 1,
        delay: .5,
        text: "vision",
        color: "#FFAAA5",
        ease: "none",
      }).to('#replace-text', {
        duration: 1,
        delay: .5,
        text: "passion",
        color: "#FF69B4",
        ease: "none",
      }).to('#replace-text', {
        duration: 1,
        delay: .5,
        text: "ingenuity",
        color: "#A0E1E5",
        ease: "none",
      }).to('#replace-text', {
        duration: 1,
        delay: .5,
        text: "excitement",
        color: "#00FA9A",
        ease: "none",
      }).to('#replace-text', {
        duration: 1,
        delay: .5,
        text: "ease",
        color: "#00FA9A",
        ease: "none",
      })
      .to('.follow-text, #replace-text-container', { opacity: 0, ease: Power4.easeInOut, duration: 1 })
      .from('.close-out', { opacity: 0, ease: Power4.easeInOut, duration: 2 })
      ;






    });
  },
};
</script>

<style scoped>
.side-scroll {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15%;
  color: white;
  overflow-x: hidden;
}

.side-scroll-container {
  z-index: 10;
}

#scrollH1 {
  font-size: 20rem;
  font-weight: 700;
  text-transform: uppercase;
  user-select: none;
  white-space: nowrap;
  z-index: 10;
}

.an-title {
  width: 100%;
  text-align: center;
  color: white;
  text-shadow: -3px 0 black;
  white-space: nowrap;
  pointer-events: none;
  font-size: 12vw;
  position: absolute;
  top: 40%;
  right: 0;
  z-index: 9;

}

.glitch {
  position: absolute;
  width: 100%;
  z-index: 9;
  overflow: hidden;
}

.top {
  -webkit-clip-path: inset(0 0 41% 0);
  clip-path: inset(0 0 41%);
  z-index: 9;
  overflow: hidden;
}

.bottom {
  -webkit-clip-path: inset(58% 0 0 0);
  clip-path: inset(58% 0 0 0);
  z-index: 9;
  overflow: hidden;
}

.intro-container {
  position: absolute;
  top: 6%;
  left: 0;
  height: 94%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}

.intro {
  font-size: 3rem;
  font-weight: 700;
  text-transform: uppercase;
  user-select: none;
  white-space: nowrap;
  z-index: 11;
}

#m1 {
  color: orange;
}

#y1 {
  color: orange;
}

#n2 {
  color: yellow;
}

#a2 {
  color: yellow;
}

#m2 {
  color: yellow;
}

#e2 {
  color: yellow;
}

#i3 {
  color: green;
}

#s3 {
  color: green;
}

#c4 {
  color: blue;
}

#h4 {
  color: blue;
}

#r4 {
  color: blue;
}

#i4 {
  color: blue;
}

#s4 {
  color: blue;
}

#period {
  color: red;
}

.follow-text {
  position: absolute;
  top: 15%;
  left: 0;
  height: 85%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
  font-size: 2rem;
  z-index: 20;
}

#follow-1 {
  position: absolute;
  top: 15%;
  left: 10%;
}

#follow-2 {
  position: absolute;
  top: 27%;
  left: 15%;
}

#follow-3 {
  position: absolute;
  top: 40%;
  left: 8%;
}

#follow-4 {
  position: absolute;
  top: 52%;
  left: 22%;
}

#replace-text-container {
  position: absolute;
  top: 65%;
  left: 15%;
  height: 100px;
  min-width: 200px;
  text-align: left;
  white-space: nowrap;
  font-size: 3.5rem;
  font-weight: bold;
  z-index: 30;
}

#replace-text {
  overflow: visible;
  height: 100%;
  min-width: 100%; /* Ensure the text takes up the available space */
  text-align: left;
  z-index: 30;
  display: inline-block; /* Ensures it behaves like a block with inline-level flexibility */
}

.close-out {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  z-index: 30;
}

#note, #thanks, #email {
  max-width: 490px;
}
#thanks {
  margin-top: 20px;
}
#email {
  margin-top: 80px;
}

@media (max-width: 768px) {
  .side-scroll {
    padding-left: 5%;
  }

  #scrollH1 {
    font-size: 20rem;
  }

  .intro {
    font-size: 1.5rem;
  }

  #note, #thanks {
    max-width: 255px;
  }
}
</style>
