<template>
    <footer>
      <p>&copy; 2024 WorkWise Solutions Llc, D.B.A. Art&#239;sNov&#233;l. All rights reserved.</p>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterComponent',
  };
  </script>
  
  <style scoped>
  footer {
    text-align: center;
    padding: 20px;
    background-color: #111;
    border-color: red 2px solid;
    color: white;
  }
  </style>
  