<template>
  <div id="app">
    <HeaderComponent />
    <main>
      <HeroComponent />
      <SideScrollComponent />
      <!--<AboutComponent />-->
    </main>
    <FooterComponent />
    <div class="custom-cursor" ref="cursor"></div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import HeaderComponent from './components/HeaderComponent.vue';
import HeroComponent from './components/HeroComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
import SideScrollComponent from './components/SideScrollComponent.vue';
//import AboutComponent from './components/AboutComponent.vue';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    HeroComponent,
    SideScrollComponent,
    //AboutComponent,
    FooterComponent
  },
  setup() {
    const cursor = ref(null);
    const cursorEnabled = ref(false);

    onMounted(() => {

      if (window.innerWidth > 768) {
        cursorEnabled.value = true;
        document.body.style.cursor = 'none';
      }


      window.addEventListener('mousemove', (event) => {
        if (!cursorEnabled.value) return;
        if (cursor.value) {
          cursor.value.style.left = `${event.clientX}px`;
          cursor.value.style.top = `${event.clientY}px`;
        }
      });
    });

    return {
      cursor
    };
  }
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  font-family: 'Roboto', sans-serif;
  background-color: #111;
  color: white;
}

.custom-cursor {
  position: fixed;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
}

@media (min-width: 768px) {
  .custom-cursor {
    display: block;
  }
}
</style>
